import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Navbar = ({ data }) => {
  const [isToggled, setToggled] = useState(true)

  const toggleCollapse = () => {
    setToggled(!isToggled)
  }
  const navClass = isToggled ? '' : 'collapse'
  const navbarData = data.edges[0].node.frontmatter

  return (
    <ul className="nav">
      <li className="nav__hamburger">
        <button onClick={toggleCollapse}>Nav</button>
      </li>
      <li className={`nav__icons ${navClass}`}>
        <ul>
          {navbarData.menuItems.map((menuItem) => (
            <li key={menuItem.iconName}>
              <a
                href={menuItem.linkUrl}
                className={`nav__icons-${menuItem.iconName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {menuItem.iconName}
              </a>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}

Navbar.propTypes = {
  data: PropTypes.shape({
    menuItems: PropTypes.arrayOf({
      iconName: PropTypes.string,
      iconUrl: PropTypes.string,
    }),
  }),
}

export default Navbar
